import firebase from 'firebase/app';
import 'firebase/firestore';

const config = {
    apiKey: "AIzaSyApUcNq-twtLI0M4wC63nJyOKSNjLuhFGM",
    authDomain: "soulsinaction.firebaseapp.com",
    databaseURL: "https://soulsinaction-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "soulsinaction",
    storageBucket: "soulsinaction.appspot.com",
    messagingSenderId: "795366336917",
    appId: "1:795366336917:web:75e6c1159cd00f1810ee9c"
  };
  
firebase.initializeApp(config);

export default firebase;