import React from 'react';
import firebase from "../utils/firebase";

/*
const validateForm = errors => {
  let valid = true;
  Object.values(errors).forEach(val => val.length > 0 && (valid = false));
  return valid;
};*/

class User extends React.Component {
/*
  constructor(props) {
    super(props);
    this.state = {
      name: null,
      email: null,
      errors: {
        name: '',
        email: '',
      }
    };
  }

  updateInput = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;

    switch (name) {
      case 'name': 
        errors.fullName = 
          value.length < 5
            ? 'Full Name must be at least 5 characters long!'
            : '';
        break;

      default:
        break;
    }

    this.setState({errors, [name]: value});
  }

  addUser = (event) => {
    event.preventDefault();
    if(validateForm(this.state.errors)) {
      console.info('Invalid Form inputs')
    }else{
      const db = firebase.firestore();
        db.settings({
            timestampsInSnapshots: true
        });
        const userRef = db.collection("earlyadopters").add({
            name: this.state.name,
            email: this.state.email
        }); 
        this.setState({
          name: "",
          email: ""
        });
        alert('¡Gracias por tu confianza!');
    }
  }
    */
    
    constructor() {
        super();
        this.state = {
         email: "",
         name: ""
        };
    }

    updateInput = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    addUser = e => {
        e.preventDefault();
        const db = firebase.firestore();
        db.settings({
            timestampsInSnapshots: true
        });
        const userRef = db.collection("earlyadopters").add({
            name: this.state.name,
            email: this.state.email
        }); 
        this.setState({
          name: "",
          email: ""
        });
        alert('¡Gracias por tu confianza!');
      };


  render() {
    return (
        <form id="contactForm" onSubmit={this.addUser}>
          <div className="row gtr-uniform">
            <div className="col-5 col-12-xsmall">
              <input
                type="text"
                name="name"
                placeholder="Nombre"
                onChange={this.updateInput}
                value={this.state.name}
                required
              />
            </div>
            <div className="col-6 col-12-xsmall">
              <input
                type="email"
                name="email"
                placeholder="Email"
                onChange={this.updateInput}
                value={this.state.email}
                required
              />
            </div>
            <div className="col-12">
								<ul className="actions">
									<li><input type="submit" value="Enviar" className="primary" /></li>
								</ul>
						</div>
          </div>
        </form>
        );
      }
   }export default User;