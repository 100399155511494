import React from 'react';

import Layout from '../components/Layout';

import Scroll from '../components/Scroll';

import ContactForm from '../components/ContactForm';

import pic1 from '../assets/images/volunteer_6_1920.jpg';
import pic2 from '../assets/images/projects_6_1280.png';
import pic3 from '../assets/images/associations_2_1920.jpg';
import config from '../../config';
const IndexPage = () => (
  <Layout>
  <section id="banner">
    <div className="inner">
      <h2>{config.heading}</h2>
      <p>{config.subHeading}</p>
      <ul className="actions special">
        <li>
        <Scroll type="id" element="one">
          <a href="#one" className="button primary">
          Voluntarios
          </a>
        </Scroll>
        </li>
        <li>
          <a href="/Entities/#one" className="button primary">
            Asociaciones
          </a>
        </li>
      </ul>
    </div>
    <Scroll type="id" element="one">
      <a href="#one" className="more">
        Saber más
      </a>
    </Scroll>
  </section>

  <section id="one" className="wrapper style1 special">
    <div className="inner">
      <header className="major">
        <h2>
          Tú creas futuro, nosotros te damos las herramientas
        </h2>
        
        <p>¿Te gustaría participar en un voluntariado? </p>
      
        <p>¿Quieres saber que asociaciones sin ánimo de lucro hay en tu zona?</p>
      
        <p>¿Te interesaría tener un seguimiento de tus donaciones?</p>

        <p className="btnomargin">
          <b>¡Todo esto y más en nuestra plataforma!</b>
        </p>
      </header>
      <ul className="icons minor">
        <li>
          <p>
            <Scroll type="id" element="volunteering">
              <a href="#volunteering">Voluntariado</a>
            </Scroll>
          </p>
          <Scroll type="id" element="volunteering">
            <a href="#volunteering" className="icon fa-heart major style1"></a>
          </Scroll>
        </li>
        <li>
          <p>
              <Scroll type="id" element="associations">
                <a href="#associations">Asociaciones</a>
              </Scroll>
          </p>
          <Scroll type="id" element="associations">
            <a href="#associations" className="icon fa-building major style4"></a>
          </Scroll>
        </li>
        <li>
          <p>
            <Scroll type="id" element="projects">
              <a href="#projects">Proyectos</a>
            </Scroll>
          </p>
          <Scroll type="id" element="projects">
            <a href="#projects" className="icon fa-compass major style2"></a>
          </Scroll>
        </li>
      </ul>
    </div>
  </section>

  <section id="two" className="wrapper alt style2">
    <section id="volunteering" className="spotlight">
      <div className="image">
        <img src={pic1} alt="" />
      </div>
      <div className="content">
        <div className="sub_content">
          <h2>
            Voluntariado
          </h2>
          <p>
            ¡Muchas entidades necesitan de tu ayuda! Te mostramos cómo puedes ayudarlas, 
            cúantas personas participan en el proyecto y qué están consiguiendo con vuestra colaboración 
          </p>
          <Scroll type="id" element="associations">
                <a href="#associations" className="icon solid fa-arrow-down circle left"></a>
          </Scroll>
        </div>
      </div>
    </section>

    <section id="associations" className="spotlight">
      <div className="image">
        <img src={pic3} alt="" />
      </div>
      <div className="content">
        <div className="sub_content">
          <h2>
            Asociaciones
          </h2>
          <p>
            ¡Toda la información de las asociaciones que tienes cerca de tí o en la zona que elijas!
            Conoce sus objetivos, proyectos y cómo contactar con ellas
          </p>
          <Scroll type="id" element="projects">
              <a href="#projects" className="icon solid fa-arrow-down circle right"></a>
          </Scroll>
        </div>
      </div>
    </section>

    <section id="projects" className="spotlight">
      <div className="image">
        <img src={pic2} alt="" />
      </div>
      <div className="content">
        <div className="sub_content">
          <h2>
            Proyectos
          </h2>
          <p>
            ¿Qué proyectos necesitan financiación? Te facilitamos toda su información, objetivos, evolución a 
            tiempo real y... ¿qué pasa después de ser financiados? 
          </p>
          <Scroll type="id" element="three">
              <a href="#three" className="icon solid fa-arrow-down circle left"></a>
          </Scroll>
        </div>
      </div>
    </section>
  </section>

  <section id="three" className="wrapper style6 special">
    <div className="inner">
      <header className="major">
        <h2>Nuestra Plataforma</h2>
        <p>
          Pon tu esfuerzo en lo que realmente te importa, nosotros nos encargamos de lo demás.
        </p>
      </header>
      <ul className="features">
        <li className="icon solid fa-compress-arrows-alt">
          <h3>Todo en uno</h3>
          <p>
          Mapa de las asociaciones, información, contacto, proyectos activos y
          voluntariado.  
          </p>
        </li>
        <li className="icon solid fa-map-marked-alt">
          <h3>Mapa</h3>
          <p>
            Mediante un mapa interactivo, verás que asociaciones hay cerca de ti
            o en el lugar que elijas y cuáles son sus metas.
          </p>
        </li>
        <li className="icon solid fa-star-half-alt">
          <h3>Tu opinión cuenta</h3>
          <p>
            Más vale una experiencia que mil certificados. Ratifica la 
            experiencia que has tenido con una asociación y haz saber 
            lo que piensas.
          </p>
        </li>
        <li className="icon fa-eye">
          <h3>Visibilidad</h3>
          <p>
            ¡Tu participación se tiene en cuenta! Cuánto más participes, 
            más peso tendrán tus opiniones y si así lo decides, más visibles 
            serán tus comentarios.
          </p>
        </li>
        <li className="icon solid fa-link">
          <h3>Conexión</h3>
          <p>
            Mediante accesos rápidos a las redes sociales, colabora con tus 
            asociaciones difundiendo lo que hacen.
          </p>
        </li>
        <li className="icon fa-question-circle">
          <h3>Y... ¿qué pasa después?</h3>
          <p>
            Sabrás que ha pasado una vez hecho un donativo o tras la finalización de un proyecto;
            también qué novedades hay de las asociaciones que te interesan 
            y cuáles són sus futuras acciones.

          </p>
        </li>
      </ul>
    </div>
  </section>

  <section id="cta" className="wrapper style4">
      <div className="inner">
        <header>
          <h2>Te avisamos cuando todo esté listo</h2>
          <p>
            Dinos tu nombre y tu email y sé de los primeros en probar la plataforma
          </p>
        </header>
      </div>
      <div className="inner">
        <ContactForm/>
      </div>

        
    </section>
  </Layout>
);

export default IndexPage;
